import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import NotFound from "./views/NotFound.vue";
import ErrorPage from "./views/ErrorPage.vue";
import store from "@/store/store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/werkboek/:slug",
      name: "book",
      props: true,
      component: () =>
        import(/* webpackChunkName: "component/book" */ "./views/Book.vue"),
      beforeEnter(routeTo, routeFrom, next) {
        store
          .dispatch("book/fetchBook", { slug: routeTo.params.slug })
          .then(book => {
            if (book === false) {
              next({ name: "404" });
            } else {
              routeTo.params.book = book;
              next();
            }
          })
          .catch(error => {
            if (error.response && error.response.status == 404) {
              next({ name: "404" });
            } else {
              next({ name: "error" });
            }
          });
      },
      children: []
    },
    {
      path: "/werkboek/:book/hoofdstuk-:id",
      name: "chapter",
      props: true,
      component: () =>
        import(/* webpackChunkName: "component/chapter" */ "./views/Chapter.vue"),
      beforeEnter(routeTo, routeFrom, next) {
        store
          .dispatch("book/fetchChapter", { id: routeTo.params.id })
          .then(chapter => {
            routeTo.params.chapter = chapter;
            next();
          })
          .catch(error => {
            if (error.response && error.response.status == 404) {
              next({ name: "404" });
            } else {
              next({ name: "error" });
            }
          });
      }
    },
    {
      path: "/werkboek/:book/hoofdstuk-:chapter/opdracht-:id",
      name: "task",
      props: true,
      component: () =>
        import(/* webpackChunkName: "component/task" */ "./views/Task.vue"),
      beforeEnter(routeTo, routeFrom, next) {
        store
          .dispatch("book/fetchTask", { id: routeTo.params.id })
          .then(task => {
            routeTo.params.task = task;
            next();
          })
          .catch(error => {
            if (error.response && error.response.status == 404) {
              next({ name: "404" });
            } else {
              next({ name: "error" });
            }
          });
      }
    },
    {
      path: "/page/:slug",
      name: "page",
      props: true,
      component: () =>
        import(/* webpackChunkName: "component/page" */ "./views/Page.vue")
    },
    {
      path: "/404",
      name: "404",
      component: NotFound,
      props: true
    },
    {
      path: "/error",
      name: "error",
      component: ErrorPage
    },
    {
      path: "*",
      redirect: { name: "404" }
    }
  ]
},
  );

router.beforeEach((to, from, next) => {
  // store.dispatch("app/resetBreadcrumbs");
  next();
});

export default router;
