<template>
  <div id="app">
    <header class="logolinks">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1">
            <vb-navbar static-top>
              <router-link
                  role="button"
                  :to="{ name: 'home' }"
                  class="navbar-brand"
                  exact
                  slot="brand"
              >
                <img src="/img/logo-large.png" class="logo" />
              </router-link>
              <template slot="collapse">
                <vb-navbar-nav right>
                  <router-link
                      role="button"
                      :to="{ name: 'home' }"
                      tag="li"
                      exact
                      :class="{overzicht: true, active: isBooksLinkActive }"
                  >
                    <a>Overzicht werkboeken</a>
                  </router-link>
                  <router-link
                      role="button"
                      :to="{ name: 'page', params: { slug: 'about' } }"
                      tag="li"
                      active-class="active"
                      class="over"
                  >
                    <a>Over Eduforce Digitaal</a>
                  </router-link>
                  <!-- <vb-dropdown tag="li">
                    <a class="dropdown-toggle" role="button">Dropdown <span class="caret"></span></a>
                    <template slot="dropdown">
                      <li><a role="button">Action</a></li>
                      <li><a role="button">Another action</a></li>
                      <li><a role="button">Something else here</a></li>
                      <li role="separator" class="divider"></li>
                      <li><a role="button">Separated link</a></li>
                    </template>
                  </vb-dropdown> -->
                </vb-navbar-nav>
              </template>
            </vb-navbar>
          </div>
        </div>
      </div>
    </header>
    <section>
      <div class="container">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-md-offset-1">
              <div class="row">
                <div class="col-xs-12  col-sm-3" v-show="showBreadcrumbsPadding">
                  &nbsp;
                </div>
                <div class="col-sm-9">
                  <router-link
                      :to="backButton"
                      v-if="backButton !== null"
                      class=""
                      style="font-size: 13px; color: #ff7900;">
                    <i class="fas fa-arrow-left"></i>  Ga terug
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div id="wrapper">
      <router-view />
    </div>
    <section>
      <footer class="footer">
        <div class="f1">
          <div class="container">
            <div class="container">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1">
                  <div class="row">
                    <div class="col-xs-12 col-sm-4">
                      <div class="fc1">
                        <h5>Eduforce Digitaal</h5>
                        <ul>
                          <li><router-link :to="{name:'home'}">Overzicht werkboeken</router-link></li>
                          <li><router-link :to="{name:'page',params:{slug:'about'}}">Over Eduforce digitaal</router-link></li>
                          <li><router-link :to="{name:'page',params:{slug:'contact'}}">Contact</router-link></li>
                          <li><router-link :to="{name:'page',params:{slug:'colofon'}}">Colofon</router-link></li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-xs-12 col-sm-4">
                      <div class="fc2">
                        <h5>Service en Voorwaarden</h5>
                        <ul>
                          <li><router-link :to="{name:'page',params:{slug:'faq'}}">Veelgestelde vragen</router-link></li>
                          <li><router-link :to="{name:'page',params:{slug:'privacy'}}">Privacy Policy</router-link></li>
                          <li><router-link :to="{name:'page',params:{slug:'terms'}}">Algemene voorwaarden</router-link></li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-xs-12 col-sm-4">
                      <div class="fc3">
                        <h5>Contact</h5>
                        <p>
                          Eduforce Educatieve Uitgeverij<br />
                          Lavendelheide 21<br />
                          9202 PD Drachten<br />
                          Email: mail@eduforce.nl<br />
                          Telefoon: 088- 0200 555<br />
                          <br />
                          <a href="https://www.eduforce.nl" target="_blank">Bekijk onze webshop</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="f2">
          <div class="container">
            <div class="container">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1">
                  &copy; {{ year }} Eduforce Digitaal. Alle rechten voorbehouden.  Eduforce Digitaal is onderdeel van Eduforce Uitgeverij.
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </section>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      isBooksLinkActive: false,
      showBreadcrumbsPadding: false
    }
  },
  watch: {
    notifications: "showNotifications",
    '$route' (to, from) {
      this.updateMenus(to.name);
    }
  },
  mounted: function() {
    this.updateMenus(this.$route.name);
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    backButton() {
      let bcs = this.breadcrumbs;
      if (bcs.length > 1) {
        let link = bcs[bcs.length - 2];
        if (link.to) {
          return link.to;
        }
      }
      return null;
    },
    ...mapState("notification", ["notifications"]),
    ...mapState("app", ["breadcrumbs"]),
    ...mapState("app", ["breadcrumbsShowPadding"])
  },
  methods: {
    ...mapActions("notification", { removeNotification: "remove" }),
    showNotifications(notifications) {
      //console.log(notifications);
      notifications.map(notification => {
        this.$vb_notify(notification.message);
        this.removeNotification(notification);
      });
    },
    updateMenus(routeName) {
      this.isBooksLinkActive = this.$_.includes(["home", "book", "chapter", "task"], routeName);
      this.showBreadcrumbsPadding = false;
      if (routeName === 'book') {
        this.showBreadcrumbsPadding = true;
      }
    }
  }
};
</script>

<style lang="scss">
footer {
  a {
    color: #575756;
    font-size: 12px;
  }
  &:hover {
    color: #575756;
  }
  .f1 {
    // background-image: url("/img/footer.png");
    background-repeat: no-repeat;
    background-position: right bottom;
    margin-top: 48px;
    background-color: #e8e8e8;
    color: #575756;
    font-size: 12px;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li a {
        color: #575756;
        font-size: 12px;
        display: inline-block;
        padding: 2px 0;
        &::before {
          content: "\f101";
          display: inline-block;
          font-family: FontAwesome;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          font-synthesis: weight style;
          font-stretch: normal;
          font-size-adjust: none;
          font-language-override: normal;
          font-kerning: auto;
          font-feature-settings: normal;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          text-align: center;
          font-size: 12px;
          margin-right: 5px;
        }
      }
    }
    p {
    }
    h5 {
      font-weight: bold;
      color: #575756;
      font-size: 12px;
    }
    .fc1,
    .fc2,
    .fc3 {
      margin: 17px 0 31px;
    }
  }
  .f2 {
    background-color: #1d1d1b;
    color: #ffffff;
    font-size: 10px;
    line-height: 30px;
  }
}

> .navbar-default {
  background-color: #ffffff;
  border-color: #009fe3;
}
.logolinks {
  border-bottom: 1px;
  border-bottom-color: #009fe3;
  border-bottom-style: solid;
  margin-bottom: 20px;
  background: linear-gradient(
          to right,
          #ffffff 0%,
          #ffffff 50%,
          #009ee2 50%,
          #009ee2 100%
  );
}
.navbar.navbar-default {
  background-color: #ffffff;
  margin-bottom: 0;
  border: none;

  .navbar-nav > li {
    > a {
      color: #ffffff;
      background-color: #009fe3;
      cursor: pointer;
      font-size: 13px;
      font-weight: bold;
      padding: 25px 15px;
    }
    > a:hover {
      text-decoration: underline;
    }
    &.active {
      > a {
        color: #ffffff;
        background-color: #00437a;
      }
    }
  }
}
</style>
