import "@/assets/scss/app.scss";

import Vue from 'vue'
import * as uiv from "uiv";
import App from './App.vue'
import router from './router'
import store from "@/store/store";
import _ from "lodash";

Object.defineProperty(Vue.prototype, "$_", { value: _ });

Vue.config.productionTip = false

Vue.use(uiv, { prefix: "vb" });

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
