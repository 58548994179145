import axios from "axios";
import qs from "qs";
import store from "@/store/store";

const apiUrl = process.env.VUE_APP_API_URL;
const apiClient = axios.create({
  baseURL: apiUrl,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

export default {
  // ------- Texts ----------
  getTexts() {
    return apiClient.get("/text");
  },

  // ------- Books ----------
  getBooks(filter = {}) {
    return apiClient.get("/book", {
      params: { filter: filter },
      paramsSerializer: params => {
        return qs.stringify(params);
      }
    });
  },
  getBook(id) {
    return apiClient.get("/book/" + id + "?expand=chapters");
  },
  getBooksBySlug(slug) {
    return apiClient.get("/book?expand=chapters", {
      params: { filter: { slug: slug}, unlock_code: store.state.app.code },
      paramsSerializer: params => {
        return qs.stringify(params);
      }
    });
  },
  getChapter(slug) {
    return apiClient.get("/chapter/" + slug + "?expand=tasks,book", {
      params: { filter: { slug: slug}, unlock_code: store.state.app.code },
      paramsSerializer: params => {
        return qs.stringify(params);
      }
    });
  },
  getTask(id) {
    return apiClient.get("/task/" + id + "?expand=chapter.book,sections", {
      params: { filter: { slug: id}, unlock_code: store.state.app.code },
      paramsSerializer: params => {
        return qs.stringify(params);
      }
    });
  }
};
