import BackendService from "@/services/BackendService";

export const namespaced = true;

export const state = {
  books: [],
  book: {
    chapters: [],
  },
  chapter: null,
  task: null
};

export const mutations = {
  SET_BOOKS(state, books) {
    state.books = books;
  },
  SET_CURRENT_BOOK(state, book) {
    state.book = book;
  },
  SET_CURRENT_CHAPTER(state, chapter) {
    state.chapter = chapter;
  },
  SET_CURRENT_TASK(state, task) {
    state.book = task;
  }
};
export const actions = {
  async fetchBooks({ state, commit, dispatch }, { filter = {}, reset = false }) {
    if (reset) {
      await dispatch("reset");
    }
    return BackendService.getBooks(filter)
      .then(({ data }) => {
        commit("SET_BOOKS", data);
      })
      .catch(error => {
        console.log("Error fetching books", error);
        throw error;
      });
  },
  reset({ state }) {
    state.offers = [];
  },
  fetchBook({ commit }, { slug }) {
    return BackendService.getBooksBySlug(slug)
      .then(({ data }) => {
        if (data.length > 0) {
          commit("SET_CURRENT_BOOK", data[0]);
          return data[0];
        } else {
          return false;
        }
      })
      .catch(error => {
        console.log("Error fetching book " + slug, error);
        throw error;
      });
  },
  fetchChapter({ commit }, { id }) {
    return BackendService.getChapter(id)
      .then(({ data }) => {
        commit("SET_CURRENT_CHAPTER", data);
        return data;
      })
      .catch(error => {
        console.log("Error fetching chapter " + id, error);
        throw error;
      });
  },
  fetchTask({ commit },  { id }) {
    return BackendService.getTask(id)
      .then(({ data }) => {
        commit("SET_CURRENT_TASK", data);
        return data;
      })
      .catch(error => {
        console.log("Error fetching task " + id, error);
        throw error;
      });
  }
};

export const getters = {
  getBooksCount: state => {
    return state.books.length;
  },
  getCurrentBook: state => {
    return state.book;
  },
  getCurrentTask: state => {
    return state.task;
  },
  getCurrentChapter: state => {
    return state.chapter;
  }
};
