export const namespaced = true;

export const state = {
  showSidebar: true,
  breadcrumbs: [],
  code: localStorage.getItem('code')
};

export const mutations = {
  TOGGLE_SIDEBAR(state) {
    state.showSidebar = !state.showSidebar;
  },
  SET_SIDEBAR(state, value) {
    state.showSidebar = value;
  },
  SET_BREADCRUMBS(state, value) {
    state.breadcrumbs = value;
  },
  SET_CODE(state, value) {
    state.code = value;
  }
};

export const actions = {
  toggleDrawer({ commit }) {
    commit("TOGGLE_SIDEBAR");
  },
  setSidebar({ commit }, show) {
    commit("SET_SIDEBAR", show);
  },
  setBreadcrumbs({ commit }, crumbs) {
    commit("SET_BREADCRUMBS", crumbs);
  },
  resetBreadcrumbs({ commit }) {
    commit("SET_BREADCRUMBS", []);
  },
  setCode({commit}, code) {
    commit("SET_CODE", code)
  }
};
