import Vue from "vue";
import Vuex from "vuex";
import * as app from "@/store/modules/app";
import * as notification from "@/store/modules/notification";
import * as text from "@/store/modules/text";
import * as book from "@/store/modules/book";


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    notification,
    text,
    book
  }
});
