<template>
  <section id="content">
    <div class="container">
      <div class="home">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1">
            <div class="filter">
              <div class="row">
                <form class="form-inline">
                  <div class="form-group col-xs-12 col-sm-12 col-md-6 col-lg-5">
                    <label for="filter_title" class="label-mobile">Zoeken: </label>
                    <input
                      v-model="filter.title"
                      id="filter_title"
                      class="form-control"
                      placeholder="Zoek op titel"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="filter_group">Groep: </label>
                    <input
                      v-model="filter.group"
                      id="filter_group"
                      class="form-control"
                      placeholder="Zoek op groep"
                    />
                  </div>
                </form>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1">
            <BookList v-model="filter"></BookList>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 interesse">
            <vb-btn type="primary-outline" href="https://www.eduforce.nl" target="_blank"><strong>Interesse in werkboeken?</strong> Kijk op Eduforce.nl</vb-btn>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { setBreadcrumbsMixin } from "../mixins/setBreadcrumbs";

import BookList from "../components/BookList";
export default {
  name: "home",
  mixins: [setBreadcrumbsMixin],
  components: {
    BookList
  },
  created() {
    this.setBreadcrumbs([{ text: "Home" }, { text: "Overzicht werkboeken" }]);
  },
  data() {
    return {
      filter: {
        title: "",
        author: "",
        isbn: "",
        group: "",
        orderBy: null
      }
    };
  },
  methods: {}
};
</script>
