<template>
  <div class="books">
    <div class="row">
      <div v-for="book in books" :key="book.id" class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
        <div class="book">
          <router-link :to="{ name: 'book', params: { slug: book.slug } }">
            <div class="cover">
              <img
                  class="img-responsive"
                  :src="book.cover"
              />
            </div>
            <h3>{{ book.title }}</h3>
            <small>Door: {{ book.author }}</small>
            <br /><br />
            <vb-btn type="primary-outline" class="btn-block">Bekijk dit boek</vb-btn>
          </router-link>
        </div>
      </div>
    </div>
    <div class="no-results" v-if="books.length === 0 && loaded">
      Geen resultaten
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { debounce } from "lodash";

export default {
  name: "BookList",
  components: {
  },
  props: {
    value: {
      type: Object,
      default: function() {
        return {
          title: "",
          author: "",
          isbn: "",
          group: "",
          orderBy: null
        };
      }
    }
  },
  data() {
    return {
      filter: this.value,
      loaded: false,
      page: 1
    };
  },
  created() {
    this.fetchBooks({});
  },
  computed: {
    ...mapState("book", ["books"])
  },
  watch: {
    filter: {
      handler: function(filter) {
        this.fetchBooks(filter);
      },
      deep: true
    }
  },
  methods: {
    fetchBooks: debounce(function(filter) {
      this.$store
        .dispatch("book/fetchBooks", { filter: filter, page: this.page })
        .then(books => {
          this.loaded = true;
        });
    }, 500),
    updateFilter() {
      this.$emit("input", this.filter);
    }
  }
};
</script>
