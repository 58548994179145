import BackendService from "@/services/BackendService";

export const namespaced = true;

export const state = {
  texts: []
};

export const mutations = {
  SET_TEXTS(state, texts) {
    state.texts = texts;
  }
};
export const actions = {
  fetchTexts({ state, commit }) {
    if (state.texts.length === 0) {
      return BackendService.getTexts()
        .then(({ data }) => {
          commit("SET_TEXTS", data);
        })
        .catch(error => {
          console.log("There was an error", error);
        });
    }
  }
};

export const getters = {
  getText: state => key => {
    return state.texts.find(text => text.key === key);
  }
};
